import React from "react";
import Services from './Services';
import SectionTitle from './SectionTitle';

const ServiceSection = () => {
  return (
    <div>
      <div className="container">
        <SectionTitle sectionTitle = "Services" />

        <div className="row d-flex justify-content-center text-center">
          <div className="col-lg-5 my-3">
            <Services
              serviceTitle="Software Development"
              serviceDescription="Control your Business with the Help of Automation and Web."
            />
          </div>

          <div className="col-lg-5 my-3">
            <Services
              serviceTitle="Digital Branding"
              serviceDescription="Speed up your Business with absolute data driven Decision."
            />
          </div>

          <div className="col-lg-5 my-3">
            <Services
              serviceTitle="Graphics Design"
              serviceDescription="Develope your design Maintaining with your theme."
            />
          </div>

          <div className="col-lg-5 my-3">
            <Services
              serviceTitle="Game Development"
              serviceDescription="Build an awsome virtual world where your imagination world would belong. "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
