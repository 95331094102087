import React from "react";
import SectionTitle from "./SectionTitle";

const Contact = (props) => {
  return (
    <div>
      <div className="container pt-5">
        <SectionTitle sectionTitle = "Contact" />
        <div className="row py-5">
          <div className="col-lg-6 pb-5">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button bg-black text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <p className="fs-3 my-auto">{props.accordionTitle1}</p>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                  <strong>We develope software/application/website using raw/core programming languages and their official open source frameworks.</strong> 
                  We are working around the world through our expertized team, our team experience is more than 10 years. We love to sort out complex problems 
                  whether it's business related or tech related. You can rely on us, as our previous client haven't any complain about our previous work.<code>Trust US.</code> 
                    <strong>50+ projects done/working.</strong>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button collapsed bg-black text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <p className="fs-3 my-auto">{props.accordionTitle2}</p>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    <strong>We will use your choosen programming languages or which would be best for your product/system, and neccessary paid tools(our end) from outside. </strong>
                      We build software or web or mobile application using core programming languages like php, python, javascript,
                      java, dart, kotlin or their frameworks like Laravel, Django, Flutter, React or nextJS. For hosting services we're comforatble
                      any platform like AWS or google cloud or microsoft azure. <code>Thank you.</code>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                  <button
                    className="accordion-button collapsed bg-black text-light"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    <p className="fs-3 my-auto">{props.accordionTitle3}</p>
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div className="accordion-body">
                    <strong>We are here to make your life more easier by providing our super professional skills on Technology used in Daily Life.</strong> .
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 ">
            <div className="contact-form px-5 pt-4 fs-5 pb-2 rounded-2">
              <div className="mb-3">
                <label form="exampleFormControlInput1" className="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                />
              </div>
              <div className="mb-3">
                <label form="exampleFormControlTextarea1" className="form-label">
                  Ask Your Query
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
                <div className="mb-3 pt-3">
                  <label form="formFile" className="form-label">
                    Attach Files (if needed)
                  </label>
                  <input className="form-control" type="file" id="formFile" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                <div className="mb-3">
                    {/* <MyBtn btnText="Send" /> */}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
