import React from "react";
import Navbar from "../shared/Navbar";
import Banner from "../shared/Banner";
import Footer from "../shared/Footer";
import ServiceSection from "../shared/ServiceSection";
import Plans from '../shared/Plans';
import Contact from '../shared/Contact';
import ProjectSection from "../shared/ProjectSection";
import Blogs from "../shared/Blogs";

const Home = () => {
  return (
    <div>

        <Navbar />
        <Banner />

        <div className="bg">
          <ServiceSection/>
          <Plans/>
          <ProjectSection/>
          <Blogs/>
          <Contact
          accordionTitle1 = "Why Should you choose us?"
          accordionTitle2 = "Our Tools and Hosting Services"
          accordionTitle3 = "Leave it to us, Take a nap" />
        </div>
        <Footer/>
        <hr/>
    </div>
  );
};

export default Home;