import React from "react";
import "./ProjectSection.css";
import SectionTitle from "./SectionTitle";
import ProjectCard from "./ProjectCard";
import { useState } from "react";
import sunPic from "../../assets/img/sunProject.png"
import emailSc from "../../assets/img/emailScrapper.png"
import epesi from "../../assets/img/epesi.png"
import Husmerk from "../../assets/img/Husmerk.png"
import somiti from "../../assets/img/somiti.png"
import fashion from "../../assets/img/fashion.png"
const ProjectSection = () => {
  const [isActive, setIsActive] = useState("false");
  const scrollDown = () =>{
    setIsActive(!isActive);
  };

  return (
    <div>
      <SectionTitle sectionTitle="Projects" />
      <div className="container">
        <div className="scroll-bg py-3 text-center my-5">
          <div className={isActive? "scroll-div" : "make-full-screen"}>
            <div className="px-2">
            <div className="scroll-object">
              <div className="row">

                {/* start loopinig this div */}
                <div className="col-lg-3 py-2">
                  <ProjectCard
                    projectImg= {sunPic}
                    projectDescription="We developed an application where we build complicated ui with integrating api end point, it was a large scale frontend application given by our honourable client from Belgium."
                  />
                </div>
                {/* end loopinig this div */}

                {/* ////////////////////////// */}
                {/* del this part after looing */}
                <div className="col-lg-3 py-2">
                  <ProjectCard
                    projectImg={emailSc}
                    projectDescription="In this emailScrapper, We scrape email and name of person, We extract this information from a website through our custom developed script using python and it's library."
                  />
                </div>
                <div className="col-lg-3 py-2">
                  <ProjectCard
                    projectImg={epesi}
                    projectDescription="We made EPESI in Bengali. Business Information Manager, Epesi CRM, is a cloud native free and open-source application that helps businesses organize, process, and store information."
                  />
                </div>
                <div className="col-lg-3 py-2">
                  <ProjectCard
                    projectImg={Husmerk}
                    projectDescription="Developed a B2C E-commerce platform, where people could sale and purchase their respective and needed products or services, build cross-platform mobile application as well."
                  />
                </div>
                <div className="col-lg-3 py-2">
                  <ProjectCard
                    projectImg={somiti}
                    projectDescription="This is a somiti management system or you could say it as small banking management system. Where a few things implemented, loan, fdr, dps etc functionality has been implemented."
                  />
                  <div className="col-lg-3 py-2">
                  <ProjectCard
                    projectImg={fashion}
                    projectDescription="Design and Developed an Entire system of an e-commerce platform categorized in Fshion and Clothings. Build online payment process through credit/debit cards or others banking system in Bangladesh."
                  />
                  </div>
                </div>
                {/* ////////////////////////// */}
                {/* del this part after looing */}
              </div>{" "}
            </div>
            </div>
          </div>
          <button
            className="py-2 px-5 bg-black text-light rounded-2 scroll-bg-btn"
            onClick={scrollDown}
          >
            {isActive? "See more" : "See Less"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
