import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./responsive.css";

import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home"
import Blogs from "./components/pages/blogs";


function App() {
  return (
    <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
        </Routes>
    </>
  );
}

export default App;