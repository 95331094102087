import React from "react";
import DetailsCard from "./DetailsCard";
import SectionTitle from "./SectionTitle";

const Plans = () => {
  return (
    <div>
      <div className="container pt-5">
        <SectionTitle sectionTitle="Plans" />
        <div className="row">
          <div className="col-lg-4">
            <DetailsCard
              cardTitle="Branding"
              cardDescription="For digital marketing/branding purposes we have few packages where you would get seo services, collecting leads, managing your social platforms like facebook, instagram and manage your paid ad campaign with appropriate data sets or specific categories for your business product or services. Please contact us for more details. Book a free consultation through email or social media links below the page."
            />
          </div>

          <div className="col-lg-4">
            <DetailsCard
              cardTitle="Development"
              cardDescription="Develope your web2 or web3 based application whether it's develope about your entire system or a particular module, we could give at a best price, we are working around the world, developed simple cron jobs/automated to build entire business application like CRM or MS. Please contact us for more details. Book a free consultation through email or social media links below the page."
            />
          </div>

          <div className="col-lg-4">
            <DetailsCard
              cardTitle="Graphics"
              cardDescription="Our experts delve deep into your brand identity, Whether you need eye-catching social media graphics, polished marketing collateral, sleek branding elements, or stunning website visuals, we've got you covered. Our versatile services cater to all your design needs under one roof. Please contact us for more details. Book a free consultation through email or social media links below the page."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
